<template>
<div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap blurb-section">
        <div class="container">
            <div class="contact-container">
                <div data-delay="4000" data-animation="slide" data-autoplay="1" data-duration="500" data-infinite="1" class="slider-2 contslider w-slider">
                    <div class="w-slider-mask">
                        <div class="slide w-slide">
                            <div class="div-block-20 contactblock">
                                <div>
                                    <img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Dark-traignle.svg" width="35" height="35" alt="" class="image" />
                                </div>
                                <h5>Benny</h5>
                                <p>
                                    Needed to know how many each were in a pound of rivets. Mr.
                                    Al Felice, responded the next day, great customer service.
                                </p>
                            </div>
                        </div>
                        <div class="slide-2 w-slide">
                            <div class="div-block-20">
                                <div>
                                    <img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Dark-traignle.svg" width="35" height="35" alt="" class="image" />
                                </div>
                                <h5>Katy</h5>
                                <p>
                                    I appreciate the quick responses when I send over an e-mail.
                                    Nothing is more frustrating than vendors who take a week to
                                    get back to you. Any time I have contacted anyone at Rapid
                                    Rivet, they have done a nice job responding to my request
                                    and any questions I might have. Thank you!
                                </p>
                            </div>
                        </div>
                        <div class="slide-3 w-slide">
                            <div class="div-block-20">
                                <div>
                                    <img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Dark-traignle.svg" width="35" height="35" alt="" class="image" />
                                </div>
                                <h5>Stephanie</h5>
                                <p>
                                    Kevin is ALWAYS on point! He is courteous and really knows
                                    his products! I’ve never had a problem with product or
                                    shipping, the Rapid Rivet team is wonderful!
                                </p>
                            </div>
                        </div>
                        <div class="slide-4 w-slide">
                            <div class="div-block-20">
                                <div>
                                    <img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Dark-traignle.svg" width="35" height="35" alt="" class="image" />
                                </div>
                                <h5>Diane</h5>
                                <p>
                                    I have been dealing with Rapid Rivet for many years. They
                                    have always been prompt, efficient and courteous.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="left-arrow-3 w-slider-arrow-left">
                        <div class="w-icon-slider-left"></div>
                    </div>
                    <div class="right-arrow-3 w-slider-arrow-right">
                        <div class="w-icon-slider-right"></div>
                    </div>
                    <div class="w-slider-nav w-round"></div>
                </div>
                <div class="div-block-54-copy contactskew">
                    <img src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg" alt="" class="image-17" />
                </div>
                <div class="div-block-20 singletest">
                    <div>
                        <img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" /><img src="/images/Star.svg" width="21" height="21" alt="" />
                    </div>
                    <h5>Katy</h5>
                    <p>
                        I appreciate the quick responses when I send over an e-mail.
                        Nothing is more frustrating than vendors who take a week to get
                        back to you. Any time I have contacted anyone at Rapid Rivet, they
                        have done a nice job responding to my request and any questions I
                        might have. Thank you!
                    </p>
                </div>
                <div class="div-block-10">
                    <div class="div-block-12">
                        <h1 class="heading">Contact Rapid Rivet & Fastener Corp.</h1>
                        <img src="/images/Asset-1.svg" width="35" height="35" alt="" class="image" />
                    </div>
                    <div class="home-section-wrap">
                        <h2 class="heading-12">Call Toll Free : 800 - ( 727 - 4378 )</h2>
                        <p class="paragraph-7 text-span-3">
                            <strong>Please do not utilize this form for RFQs or Quote Requests as your message may be missed.<br><br></strong>
                            Our Rapid Quoting System can be used on any products in our website inventory for <a href="/store/login" class="link-61 text-span-3" style="display: inline;"><strong>registered Rapid Rivet Customers</strong></a>. If you do not see the product you need listed in our inventory or have a longer list for quoting, please use the <a href="/store/quick-quote" class="link-60 text-span-3" style="display: inline;"><strong>Quick Quote</strong></a> form to attach the list in the form of a spreadsheet.</p>
                        <div class="w-form">
                            <b-form @submit="onSubmit" style="display: block; overflow: hidden;">
                                <div class="_14"></div>
                                <div class="div-block-48">
                                    <div class="div-block-49">
                                        <label for="Firstname-3" class="field-label-16">First name *</label><input type="text" class="w-input" maxlength="256" name="Firstname" v-model="form.firstname" data-name="Firstname" id="Firstname-3" />
                                        <span class="text-span-3 paragraph-tiny" v-if="errors.has('firstname')">{{ errors.get('firstname') }}</span>
                                    </div>
                                    <div class="_35"></div>
                                    <div class="div-block-50">
                                        <label for="Lastname-3" class="field-label-17">Last name *</label><input type="text" class="w-input" maxlength="256" name="Lastname" v-model="form.lastname" data-name="Lastname" id="Lastname-3" />
                                        <span class="text-span-3 paragraph-tiny" v-if="errors.has('lastname')">{{ errors.get('lastname') }}</span>
                                    </div>
                                </div>
                                <div class="_14"></div>
                                <div class="div-block-48">
                                    <div class="div-block-49">
                                        <label for="Company-Name-2" class="field-label-18">Company name *</label><input type="text" class="w-input" maxlength="256" name="Company-Name" v-model="form.companyname" data-name="Company Name" id="Company-Name-2" />
                                        <span class="text-span-3 paragraph-tiny" v-if="errors.has('companyname')">{{ errors.get('companyname') }}</span>
                                    </div>
                                    <div class="_35"></div>
                                    <div class="div-block-50 typein">
                                        <label for="Type-of-Company" class="field-label-19">Type of industry *</label>
                                        <v-select
                                            :options="industrylist"
                                            v-model="form.typeofindustry"
                                            :state="!errors.has('typeofindustry')"
                                            @input="errors.clear('typeofindustry')"
                                            :reduce="(industrylist) => industrylist.value"
                                            label="text"
                                            id="Type-of-Company"
                                            :clearable="false"
                                            placeholder="Please select"
                                        ></v-select>
                                        <span class="text-span-3 paragraph-tiny" v-if="errors.has('typeofindustry')">{{ errors.get('typeofindustry') }}</span>
                                    </div>
                                </div>
                                <div class="_14"></div>
                                <div class="div-block-48">
                                    <div class="div-block-49">
                                        <label for="Phone" class="field-label-20">Phone *</label><input type="number" class="w-input" maxlength="10" name="Phone" v-model="form.phone" data-name="Phone" pattern="^[0-9-+s()]*$" minlength="10" id="phoneField" />
                                        <span class="text-span-3 paragraph-tiny" v-if="errors.has('phone')">{{ errors.get('phone') }}</span>
                                    </div>
                                    <div class="_35"></div>
                                    <div class="div-block-50">
                                        <label for="Email-2" class="field-label-21">EMail *</label><input type="email" class="w-input" maxlength="256" name="Email" v-model="form.email" data-name="Email" id="Email-2" />
                                        <span class="text-span-3 paragraph-tiny" v-if="errors.has('email')">{{ errors.get('email') }}</span>
                                    </div>
                                </div>
                                <div class="_14"></div>
                                <div class="div-block-48">
                                    <div class="div-block-49">
                                        <label for="How-did-you-find-us" class="field-label-22">How did you find us?</label>
                                        <v-select
                                            :options="finduslist"
                                            v-model="form.findus"
                                            :reduce="(finduslist) => finduslist.value"
                                            label="text"
                                            id="How-did-you-find-us"
                                            :clearable="false"
                                            placeholder="Please select"
                                        ></v-select>
                                    </div>
                                </div>
                                <div class="_14"></div>
                                <div class="div-block-48">
                                    <div class="div-block-50">
                                        <label for="message-2" class="field-label-23">Message *</label><textarea maxlength="300" id="message-2" name="message" v-model="form.message" data-name="message" class="textarea-2 w-input"></textarea>
                                        <span class="text-span-3 paragraph-tiny" v-if="errors.has('message')">{{ errors.get('message') }}</span>
                                    </div>
                                </div>
                                <div class="_14"></div>
                                <input type="submit" value="Submit" :disabled="btnDisable" data-wait="Please wait...." class="button w-button" />
                            </b-form>
                            <div class="w-form-done" style="display: block;" v-if="passorfail == true">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div class="w-form-fail" style="display: block;" v-if="passorfail == false">
                                <div>
                                    Oops! Something went wrong while submitting the form.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <CommonFoot></CommonFoot>
</div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import FormErrors from "@/core/FormErrors.js";

export default {
    components: {
        CommonHead,
        CommonFoot,
        "v-select": vSelect,
    },
    data() {
        return {
            form: new Form({
              firstname: '',
              lastname: '',
              companyname: '',
              typeofindustry: '',
              phone: '',
              email: '',
              findus:'',
              message:''
            }),
            industrylist: [
                { value: "Military", text: "Military" },
                { value: "Defense", text: "Defense" },
                { value: "Medical", text: "Medical" },
                { value: "Industrial", text: "Industrial" },
                { value: "Aerospace", text: "Aerospace" },
                { value: "Technology", text: "Technology" },
            ],
            finduslist: [
                { value: "Google", text: "Google" },
                { value: "Bing", text: "Bing" },
                { value: "Customer History", text: "Customer History" },
                { value: "Referral", text: "Referral" },
                { value: "Thomasnet", text: "Thomasnet" },
                { value: "Other", text: "Other" },
            ],
            btnDisable:false,
            errors: new FormErrors,
            passorfail: null
        }
    },
    methods: {
        onSubmit(e){
            e.preventDefault();
            console.log(e);
            this.btnDisable = true;
            const errors = {};
            const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
            if (!this.form.firstname) {
                const firstname = ["Required Field"];
                errors.firstname = firstname;
            }
            if (!this.form.lastname) {
                const lastname = ["Required Field"];
                errors.lastname = lastname;
            }
            if (!this.form.companyname) {
                const companyname = ["Required Field"];
                errors.companyname = companyname;
            }
            if (!this.form.typeofindustry) {
                const typeofindustry = ["Required Field"];
                errors.typeofindustry = typeofindustry;
            }
            if (!this.form.phone) {
                const phone = ["Required Field"];
                errors.phone = phone;
            }
            if (!this.form.email) {
                const email = ["Required Field"];
                errors.email = email;
            } else if (reg.test(this.form.email) === false) {
                const emailv = ["Valid Email Required"];
                errors.email = emailv;
            }
            if (!this.form.message) {
                const message = ["Required Field"];
                errors.message = message;
            }
            this.errors.record(errors);
            if (!this.errors.any()) {
                this.$store.dispatch("requestContact", this.form).then(res => {
                    this.$notify({
                        text: "Thank you! Your submission has been received!",
                        type: "success"
                    });
                    this.passorfail = true;
                    this.form = new Form({
                        firstname: '',
                        lastname: '',
                        companyname: '',
                        typeofindustry: '',
                        phone: '',
                        email: '',
                        findus:'',
                        message:''
                    });
                    setTimeout(() => {
                        this.btnDisable = false;
                    }, 300);
                    
                    return false;
                }).catch(error => {
                    if (error.response && error.response.data) {
                        this.$notify({
                            text: "There is a validation error. Please correct the fields outlined in red and try again.",
                            type: "error"
                        });
                        this.passorfail = false;
                        setTimeout(() => {
                            this.btnDisable = false;
                        }, 300);
                        return false;
                    } else {
                        this.$notify({
                            text: "Something went wrong!",
                            type: "error"
                        });
                        this.passorfail = false;
                        setTimeout(() => {
                            this.btnDisable = false;
                        }, 300);
                        return false;
                    }
                });
            }else{
                this.$notify({
                    text: "There is a validation error. Please correct the fields in red and try again.",
                    type: "error"
                });
                this.passorfail = false;
                setTimeout(() => {
                    this.btnDisable = false;
                }, 300);
                return false;
            }
        }
    }
};
</script>

<style>
</style>
