<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap">
      <div class="container">
        <div class="quick-quote-container">
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">Quick Quote</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt
                class="image"
              />
            </div>
            <div class="home-section-wrap">
              <h5 class="heading-5">
                Or Call Toll Free : 800 - RapidRT ( 727 - 4378 )
              </h5>
              <p class="paragraph-5">
                Need a quick quote, fill out the below form and one of our
                experienced sales associated will contact you shortly.
              </p>
              <div class="w-form">
                <form
                  id="wf-form-Quick-Quote"
                  name="wf-form-Quick-Quote"
                  data-name="Quick Quote"
                  @submit="onSubmit"
                  class="w-clearfix"
                  enctype="multipart/form-data"
                >
                  <!-- <div class="_14"></div>
                                    <div class="div-block-48">
                                        <div
                                            class="div-block-49"
                                            :class="form.errors.get('firstname')?'haserror':''"
                                            :invalid-feedback="form.errors.get('firstname')"
                                        >
                                            <label for="First-Name" class="field-label">First name *</label>
                                            <input
                                                type="text"
                                                class="w-input"
                                                maxlength="256"
                                                v-model="form.firstname"
                                                :state="!form.errors.has('firstname')"
                                                @input="form.errors.clear('firstname')"
                                                id="First-Name"
                                            >
                                            <span
                                                class="error-msg"
                                                v-if="form.errors.get('firstname')"
                                            >Required Field</span>
                                        </div>
                                        <div class="_35"></div>
                                        <div
                                            class="div-block-50"
                                            :class="form.errors.get('lastname')?'haserror':''"
                                            :invalid-feedback="form.errors.get('lastname')"
                                        >
                                            <label for="Last-Name" class="field-label-2">Last name *</label>
                                            <input
                                                type="text"
                                                class="w-input"
                                                maxlength="256"
                                                v-model="form.lastname"
                                                :state="!form.errors.has('lastname')"
                                                @input="form.errors.clear('lastname')"
                                                id="Last-Name"
                                            >
                                            <span
                                                class="error-msg"
                                                v-if="form.errors.get('lastname')"
                                            >Required Field</span>
                                        </div>
                                    </div>
                                    <div class="_14"></div>
                                    <div class="div-block-48">
                                        <div
                                            class="div-block-49"
                                            :class="form.errors.get('companyname')?'haserror':''"
                                            :invalid-feedback="form.errors.get('companyname')"
                                        >
                                            <label
                                                for="Company-Name"
                                                class="field-label-3"
                                            >Company name *</label>
                                            <input
                                                type="text"
                                                class="w-input"
                                                maxlength="256"
                                                v-model="form.companyname"
                                                :state="!form.errors.has('companyname')"
                                                @input="form.errors.clear('companyname')"
                                                id="Company-Name"
                                            >
                                            <span
                                                class="error-msg"
                                                v-if="form.errors.get('companyname')"
                                            >Required Field</span>
                                        </div>
                                        <div class="_35"></div>
                                        <div
                                            class="div-block-50"
                                            :class="form.errors.get('industry')?'haserror':''"
                                            :invalid-feedback="form.errors.get('industry')"
                                        >
                                            <label
                                                for="Type-of-Industry"
                                                class="field-label-4"
                                            >Type of industry *</label>
                                            <input
                                                type="text"
                                                class="w-input"
                                                v-model="form.industry"
                                                :state="!form.errors.has('industry')"
                                                @input="form.errors.clear('industry')"
                                                id="Type-of-Industry"
                                            >
                                            <span
                                                class="error-msg"
                                                v-if="form.errors.get('industry')"
                                            >Required Field</span>
                                        </div>
                                    </div>
                                    <div class="_14"></div>
                                    <div class="div-block-48">
                                        <div class="div-block-49">
                                            <label for="Phone-2" class="field-label-5">Phone *</label>
                                            <input
                                                type="text"
                                                class="w-input"
                                                v-model="form.phone"
                                                id="Phone-2"
                                            >
                                        </div>
                                        <div class="_35"></div>
                                        <div
                                            class="div-block-50"
                                            :class="form.errors.get('email')?'haserror':''"
                                            :invalid-feedback="form.errors.get('email')"
                                        >
                                            <label for="Email-2" class="field-label-6">EMail *</label>
                                            <input
                                                type="email"
                                                class="w-input"
                                                maxlength="256"
                                                v-model="form.email"
                                                :state="!form.errors.has('email')"
                                                @input="form.errors.clear('email')"
                                                id="Email-2"
                                            >
                                            <span
                                                class="error-msg"
                                                v-if="form.errors.get('email')"
                                            >Required Field</span>
                                        </div>
                                    </div>
                                    <div class="_14"></div> -->
                  <div class="div-block-48">
                    <!-- <div
                                            class="div-block-49"
                                            :class="form.errors.get('finduson')?'haserror':''"
                                            :invalid-feedback="form.errors.get('finduson')"
                                        >
                                            <label
                                                for="How-did-you-find-us"
                                                class="field-label-7"
                                            >How did you find us?</label>
                                            <input
                                                type="text"
                                                maxlength="256"
                                                v-model="form.finduson"
                                                :state="!form.errors.has('finduson')"
                                                @input="form.errors.clear('finduson')"
                                                id="How-did-you-find-us"
                                                class="w-input"
                                            >
                                            <span
                                                class="error-msg"
                                                v-if="form.errors.get('finduson')"
                                            >Required Field</span>
                                        </div>
                                        <div class="_35"></div> -->
                    <div
                      class="div-block-50"
                      :class="form.errors.get('specsheet') ? 'haserror' : ''"
                      :invalid-feedback="form.errors.get('specsheet')"
                    >
                      <label for="name" class="field-label-8"
                        >Upload Spec Sheet</label
                      >
                      <div class="div-block-51">
                        <div class="div-block-52">
                          <input
                            type="file"
                            id="file"
                            ref="file"
                            class="w-input-file"
                            @change="handleFileUpload"
                          />
                          <span
                            class="error-msg"
                            v-if="form.errors.get('specsheet')"
                            >Required Field</span
                          >
                        </div>
                        <p class="caption">
                          Browse to the document you intend to upload. "file
                          format supported jpeg, pdf, csv, xls
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="_35"></div>
                  <div class="div-block-48">
                    <div
                      class="div-block-49"
                      :class="form.errors.get('partnumber') ? 'haserror' : ''"
                      :invalid-feedback="form.errors.get('partnumber')"
                    >
                      <label for="Part-Number" class="field-label-9"
                        >Part Number *</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.partnumber"
                        :state="!form.errors.has('partnumber')"
                        @input="form.errors.clear('partnumber')"
                        id="Part-Number"
                      />
                      <span
                        class="error-msg"
                        v-if="form.errors.get('partnumber')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div
                      class="div-block-50">
                      <label for="Notes" class="field-label-10">Other Requirements </label>
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.notes"
                        id="Notes"
                      />
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      class="div-block-49"
                      :class="form.errors.get('targetprice') ? 'haserror' : ''"
                      :invalid-feedback="form.errors.get('targetprice')"
                    >
                      <label for="Target-Price-EA" class="field-label-11"
                        >Target Price (EA) *</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.targetprice"
                        :state="!form.errors.has('targetprice')"
                        @input="form.errors.clear('targetprice')"
                        id="Target-Price-EA"
                      />
                      <span
                        class="error-msg"
                        v-if="form.errors.get('targetprice')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div
                      class="div-block-50"
                      :class="form.errors.get('manufacturer') ? 'haserror' : ''"
                      :invalid-feedback="form.errors.get('manufacturer')"
                    >
                      <label for="Manufacturer" class="field-label-12"
                        >Manufacturer *</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.manufacturer"
                        :state="!form.errors.has('manufacturer')"
                        @input="form.errors.clear('manufacturer')"
                        id="Manufacturer"
                      />
                      <span
                        class="error-msg"
                        v-if="form.errors.get('manufacturer')"
                        >Required Field</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      class="div-block-50"
                      :class="form.errors.get('material') ? 'haserror' : ''"
                      :invalid-feedback="form.errors.get('material')"
                    >
                      <label for="Material" class="field-label-13"
                        >Material *</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.material"
                        :state="!form.errors.has('material')"
                        @input="form.errors.clear('material')"
                        id="Material"
                      />
                      <span class="error-msg" v-if="form.errors.get('material')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div
                      class="div-block-49"
                      :class="form.errors.get('quantity') ? 'haserror' : ''"
                      :invalid-feedback="form.errors.get('quantity')"
                    >
                      <label for="Quantity" class="field-label-14"
                        >Quantity *</label
                      >
                      <input
                        type="number"
                        class="w-input"
                        maxlength="256"
                        v-model="form.quantity"
                        :state="!form.errors.has('quantity')"
                        @input="form.errors.clear('quantity')"
                        id="Quantity"
                      />
                      <span class="error-msg" v-if="form.errors.get('quantity')"
                        >Required Field</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div
                      class="div-block-49"
                      :class="form.errors.get('uom') ? 'haserror' : ''"
                      :invalid-feedback="form.errors.get('uom')"
                    >
                      <label for="Quantity" class="field-label-14">UOM *</label>
                      <v-select
                        :options="uom_items"
                        v-model="form.uom"
                        :reduce="(uom_items) => uom_items.value"
                        label="text"
                        id="uom_items"
                        :clearable="false"
                        placeholder="Please select"
                        required
                      ></v-select>
                      <span class="error-msg" v-if="form.errors.get('uom')"
                        >Required Field</span
                      >
                    </div>
                    <div class="_35"></div>
                    <div
                      class="div-block-49"
                      :class="form.errors.get('needby') ? 'haserror' : ''"
                      :invalid-feedback="form.errors.get('needby')"
                    >
                      <label for="Need-By" class="field-label-15"
                        >need by*</label
                      >
                      <input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        v-model="form.needby"
                        :state="!form.errors.has('needby')"
                        @input="form.errors.clear('needby')"
                        id="Need-By"
                      />
                      <span class="error-msg" v-if="form.errors.get('needby')"
                        >Required Field</span
                      >
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-50 captcha">
                    <div
                      data-sitekey="6LdoywEVAAAAAI3YaQRr8jQvIUcjS854I-Vin_Iu"
                      class="w-form-formrecaptcha g-recaptcha g-recaptcha-error g-recaptcha-disabled"
                    ></div>
                  </div>
                  <div class="_14"></div>
                  <input
                    type="submit"
                    value="Request a Quote"
                    data-wait="Please wait..."
                    class="button w-button"
                  />
                </form>
                <div class="success" v-if="is_pass == true">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div class="error" v-if="is_fail == true">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="quick-quote-bg">
            <img
              src="/images/asoggetti-w0vtHS-M6E4-unsplash-6eff68ed-p-1080.jpeg"
              srcset="
                /images/asoggetti-w0vtHS-M6E4-unsplash-6eff68ed-p-1080-p-500.jpeg  500w,
                /images/asoggetti-w0vtHS-M6E4-unsplash-6eff68ed-p-1080.jpeg       1080w
              "
              sizes="(max-width: 991px) 100vw, (max-width: 1279px) 954.7791748046875px, 75vw"
              alt
              class="image-17"
            />
          </div>
        </div>
      </div>
    </div>

    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import axios from "axios";
import FormErrors from "@/core/FormErrors";
import { mapGetters } from "vuex";
import ResourceMixin from "../mixins/resources";
import vSelect from "vue-select";

export default {
  components: {
    CommonHead,
    CommonFoot,
    "v-select": vSelect,
  },
  data() {
    return {
      isLoading: false,
      is_pass: false,
      is_fail: false,
      form: {
        firstname: "",
        lastname: "",
        companyname: "",
        industry: "",
        phone: "",
        email: "",
        finduson: "",
        specsheet: "",
        partnumber: "",
        notes: "",
        targetprice: "",
        manufacturer: "",
        material: "",
        quantity: "",
        uom: "EA",
        needby: "",
        user_id: "",
        quote_type: "quick-quote",
      },

      uom_items: [
        { value: "EA", text: "EA" },
        { value: "LB", text: "LB" },
      ],
    };
  },
  beforeMount() {
    this.initForm();
  },
  mounted() {
    if (this.user.id != 0) {
      this.form.firstname = this.user.firstname;
      this.form.lastname =
        this.user.lastname != "" ? this.user.lastname : "test";
      this.form.email = this.user.email;
      this.form.companyname = this.user.companyname;
      this.form.user_id = this.user.id;
      this.form.phone = this.user.phoneno;
      this.form.finduson = this.user.findus;
      this.form.industry = this.user.industry;
      this.form.companytype = this.user.companytype;
    }
  },
  mixins: [ResourceMixin],
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    initForm() {
      this.form = new Form({
        firstname: "",
        lastname: "",
        companyname: "",
        industry: "",
        phone: "",
        email: "",
        finduson: "",
        specsheet: "",
        partnumber: "",
        notes: "",
        targetprice: "",
        manufacturer: "",
        material: "",
        quantity: "",
        needby: "",
        user_id: "",
        status: "pending",
        quote_type: "quick-quote",
      });
    },
    onSubmit(evt) {
      evt.preventDefault();

      let formData = new FormData();
      formData.append("firstname", this.form.firstname);
      formData.append("lastname", this.form.lastname);
      formData.append("companyname", this.form.companyname);
      formData.append("industry", this.form.industry);
      formData.append("phone", this.form.phone);
      formData.append("email", this.form.email);
      formData.append("finduson", this.form.finduson);
      formData.append("specsheet", this.form.specsheet);
      formData.append("partnumber", this.form.partnumber);
      formData.append("notes", this.form.notes);
      formData.append("targetprice", this.form.targetprice);
      formData.append("manufacturer", this.form.manufacturer);
      formData.append("material", this.form.material);
      formData.append("quantity", this.form.quantity);
      formData.append("needby", this.form.needby);
      formData.append("uom", this.form.uom);
      formData.append("user_id", this.form.user_id);
      formData.append("quote_type", "quick-quote");
      formData.append("status", "pending");
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.is_fail = false;
      axios
        .post("/api/quickquote", formData, config)
        .then(() => {
          this.initForm();
          this.is_pass = true;
          this.$router.push("/store/quote/thankyou");
        })
        .catch((error) => {
          let errorsfetch = error.response.data.errors;
          this.form.errors = new FormErrors();
          this.form.errors.record(errorsfetch);

          this.is_pass = false;
          //this.is_fail = true;
        });
    },
    handleFileUpload(e) {
      this.form.specsheet = e.target.files[0];
    },
  },
};
</script>
<style>
.page--quickquote .error {
  color: red;
}
.page--quickquote .success {
  color: green;
}
</style>